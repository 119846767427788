import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

// import FurtherReading from "../components/furtherReading"

const AboutDomainNames = () => (
  <Layout>
    <SEO
      title="10 Things You Should Know About Domain Names"
      description="Everything you need to know about a domain name. It is a unique identifier to help people find your website online, this list makes it easier to get set up."
    />
    <h1>Introduction to Domain Names</h1>
    <p>
      I have bought many domain names over the years, some I still own today.
      When it comes to knowledge around domains, we don’t need to know
      everything but some things definitely help. This list will help you make a
      better decision when you are buying yours. I will answer some questions I
      get frequently like where to buy a domain name and how much it costs.
      Also, security and optional extras.
    </p>

    <div style={{ textAlign: "center" }}>
      <img
        src="https://i.imgur.com/A5WT7lm.jpg"
        alt="campbell data science how to domain name"
        description="Campbell data science 10 things you should know before buying a domain name"
      />
    </div>
    <p>
      Photo by 
      <a
        href="https://unsplash.com/@amadorloureiroblanco?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Amador Loureiro
      </a>
       on Unsplash
    </p>
    <p>
      If you need help on anything on this page, get in touch with me through
      via email.
    </p>
    <h2>1 - Buying a domain name is really easy</h2>
    <p>
      I’m often asked how to buy a domain name and I always say it’s easy and
      can be done in a few minutes. The hardest part is choosing a name, all
      websites that sell domains have a search function that tell you if the
      name is available. I have tried a few domain registrars and{" "}
      <a
        href="https://bit.ly/cds-namecheap"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        namecheap
      </a>{" "}
      is my favourite. I like their user interface and updating settings is
      straight forward. Namecheap is popular so if you do get stuck, there are
      lots of guides online.
    </p>
    <h2>2 - Domain names are relatively cheap</h2>
    <p>
      Buying a domain name will likely be the cheapest part of putting together
      a professional website. Most basic co dot uk and dot com domains are
      around £10 a year. You can find some really expensive domains but I won’t
      get into that here. It’s usually when people buy a domain and list it for
      a higher price trying to make a profit.
    </p>
    <h2>3 - Domain names are the one thing you can’t get for free</h2>
    <p>
      Some site builders like{" "}
      <a
        href="https://www.wix.com/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Wix
      </a>{" "}
      often bundle in a free domain in paid plans but the truth is someone
      always has to pay for the domain name. You can get hosting for free with
      sites like{" "}
      <a
        href="https://www.netlify.com/pricing/"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Netlify
      </a>{" "}
      and{" "}
      <a
        href="https://www.heroku.com/pricing"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Heroku
      </a>
      . You can get database storage for free for a time with amazon web
      services. You can even get a website designed, made and uploaded for
      “free” if you can code or hire a new developer offering free work. The one
      thing we will always have to pay for is a domain name.
    </p>
    <h2>4 - You never own a domain name</h2>
    <p>
      I mean the registrar owns the domain name and we rent it from them. We do
      this to have a nice and memorable web address when someone tries to view
      our content through the web. We pay yearly rent on domain names and we can
      set them to renew automatically.
    </p>
    <p>
      One of the largest central registrars is{" "}
      <a
        href="https://www.verisign.com/en_GB/company-information/index.xhtml"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        Verisign
      </a>
      , they own a lot of top level domains like dot com and dot net. Most other
      registrars like 1&1 don’t actually own the domain names, they resell and
      provide services around web security and registration.
    </p>
    <h2>5 - There is no best place to get a domain name</h2>
    <p>
      When clients ask where the best place to a buy a domain name from is, I
      recommend{" "}
      <a
        href="https://bit.ly/cds-namecheap"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        namecheap
      </a>{" "}
      because it’s my favourite and I am familiar with it. I also say that I
      haven’t tried them all and just because it’s my favourite, doesn’t mean it
      will be yours too. I encourage clients to look around and ask their
      friends who they went with.
    </p>
    <p>
      Registrars all have a very similar list of services. The thing that
      separates them is user interface of their site and customer care. You will
      probably never have to deal with their customer care so that is why being
      able to navigate their user area easily is one of my highest priorities.
    </p>
    <h2>6 - A domain name is only half of a minimum solution</h2>
    <p>
      At the very least, to get your website up and running, you need a domain
      name and web hosting. I recommend getting your domain name from a
      different place to your host or at least buy them separately. This will
      give you the freedom to change company in the future. If you bought your
      domain name and hosting plan all through Wix for example, it can be
      difficult to move later on.
    </p>
    <p>
      Web hosting is fairly straight forward to set up. As I mentioned earlier,
      there are even some great free web hosts you can use. In the past, the
      quality of free web hosting was questionable but now we have Netlify,
      Heroku and GitHub pages. The downside is that you will need some technical
      knowledge to get up and running.
    </p>
    <h2>7 - Domain names are easy to manage</h2>
    <p>
      In the settings page in your account, you can set the domain to auto renew
      every year so you don’t have to think about it. If you don’t want a domain
      name anymore, you can set the auto renew to off and let it expire.
    </p>
    <p>
      You will get email notifications from your registrar letting you know it
      is due to expire. You even get a period after expiry where you can renew.
      After this period, it goes back up for auction and becomes available to
      the public again. If you have a valuable domain, you can list it for sale
      yourself.
    </p>
    <p>
      Forwarding the domain to your web host is simple and again there are
      guides everywhere. I use Siteground as my host right now and it is as
      simple as copying two lines of text from the Siteground settings to the
      namecheap settings. Here's my{" "}
      <Link to="/siteground-review/" style={{ textDecoration: "none" }}>
        review of Siteground
      </Link>
      .
    </p>
    <h2>8 - There are lots of domain names left</h2>
    <p>
      It might seem like whatever you think off is gone but there are still lots
      of domain names left out there to register. It is better to keep a domain
      name short so it is memorable. This isn’t as much of a problem now because
      a lot of people reach site via google search and social media these days.
      When was the last time you typed in a whole web address?
    </p>
    <p>
      I have heard stories of people being stuck for a name and using a
      thesaurus for synonyms and even using a translator to find words in
      another language. The founder of the JavaScript framework Vue js named it
      after translating the word view into French. It’s more difficult if your
      government name is taken but registrars often have different extensions to
      choose from for example yourname.io and yourname.dev. The prices vary
      quite a lot, I just had a look and datascience.site costs £1953.23,
      datascience.host costs £1.03.{" "}
      <a
        href="https://bit.ly/cds-namecheap"
        target="_blank"
        rel="noopener noreferrer nofollow"
        style={{ textDecoration: "none" }}
      >
        Have a play yourself
      </a>
      .
    </p>
    <h2>9 - You can buy multiple extensions for the same site</h2>
    <p>
      You might find that your desired domain name has multiple extensions for
      sale. e.g. yoursite.com and yoursite.co.uk. If it’s a personal website, I
      recommend buying the dot com site no matter where you are in the world
      unless you really want to let be know you are based in the UK. For
      business, I recommend you get both if you can.
    </p>
    <p>
      Names are cheap and you can redirect one to the other if someone searches
      your site with the wrong extension. There are many arguments online about
      the choices to make here.
    </p>
    <h2>10 - Security is important</h2>
    <p>
      I like namecheap because it offers WhoisGuard privacy protection for free
      when you register a domain. When you buy a domain, you enter personal
      information like your address and phone number. privacy protection hides
      this and ensures people can’t find you easily.
    </p>
    <p>
      You don’t need to worry about{" "}
      <a
        href="https://www.globalsign.com/en/ssl-information-center/what-is-an-ssl-certificate"
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "none" }}
      >
        SSL certificates
      </a>{" "}
      at the domain name buying stage. You set it up when hosting. Some hosts
      offer a free SSL certificate that lets you display the https in front of
      your domain. It lets users know that your site is secure from certain
      threats. If you go on a http website and google chrome shows it as not
      secure, it can make some users nervous.
    </p>
    <h1>Bonus Custom Domain Information</h1>
    <h2>Bonus 1 - About subdirectories and subdomains</h2>
    <p>
      Again, you don’t have to worry about this at the domain name buying stage
      but this will help later on as it affects the way your address in rendered
      in the search bar.
    </p>
    <p>
      If you had an app hosted on your site you could approach it as follows;
    </p>
    <p>Subdirectory example - mywebsite.com/app</p>
    <p>Subdomain example - app.mywebsite.com</p>
    <p>
      There are different ways of showing different pages associated with your
      domain. Subdirectories and subdomains are two of them as shown above.
      There are good reasons for both, they are both simple to set up but
      subdirectories are far easier. I recommend using subdirectories for the
      different pages of your website for example blog content and about pages.
      I recommend adding relevant but stand-alone content like an app to a
      subdomain.
    </p>
    <h2>Bonus 2 - Search engine optimisation</h2>
    <p>
      If you are interested in SEO, your domain name doesn’t count towards your
      ranking as much as other factors. Much more important things are the
      quality of your content, e.g. the structure of your site and the quality
      of external links pointing to your site.
    </p>
    <p>
      Some people go for branding and others go for the obvious of what the site
      is about. Which one do you think would have got more attention, google.com
      or thebestsearchengine.com?
    </p>
    <h2>Bonus 3 - There are lots of names for the same thing</h2>
    <p>
      Setting up a website can be confusing because people use different terms
      for the same thing. It’s the same thing with jargon in any industry. I
      have seen and heard so many questions about X thing vs Y thing when X and
      Y are the same thing. Of course there might be some slight differences. My
      example here is domain name and URL, consider them the same thing for now.
    </p>
    <Link to="/" style={{ textDecoration: "none" }}>
      Go back to the homepage
    </Link>
  </Layout>
)

export default AboutDomainNames
